import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import CountryLanguageSelector from '@cms/components/CountryLanguageSelector'
import type { CMSPageProps } from '@cms/utils/types'
import { getStaticPageFallbackProps, isDefaultOrEmptyLocale } from '@cms/utils/utils'
import Page from '@components/Layout/Page'
import i18nConfig from '@config/i18n'
import { getAppConfigProps } from '@knauf-group/ct-shared-nextjs/client'
import { sharedContentfulProps } from '@knauf-group/ct-shared-nextjs/web/lib/sharedContentfulProps'
import { Logger } from '@lib/logger'
import { useTheme } from '@mui/material/styles'

import nextI18next from '../next-i18next.config'

const LanguageSelector: React.FC<CMSPageProps> = ({
  headerEntries,
  footerEntries,
  cmsPageData,
  breadcrumbLinks,
  locale,
  appsConfig,
}) => {
  const cmsPageFields = cmsPageData
  const theme = useTheme()

  return (
    <Page
      headerEntries={headerEntries}
      footerEntries={footerEntries}
      cmsPageFields={cmsPageFields}
      breadcrumbLinks={breadcrumbLinks}
      isMiniFooter={isDefaultOrEmptyLocale(locale)}
      isMiniHeader={isDefaultOrEmptyLocale(locale)}
      locale={locale}
      sx={{
        '& .page-wrapper': {
          backgroundColor: theme.palette.background.background,
        },
      }}
      appsConfig={appsConfig}
    >
      <CountryLanguageSelector
        dataCy="language-selector"
        regions={{
          europe: i18nConfig?.countries?.europe,
          america: i18nConfig?.countries?.america,
          asiaPacific: i18nConfig?.countries?.asia,
          africaMiddleEastIndia: i18nConfig?.countries?.africa,
          global: i18nConfig?.countries?.global,
        }}
      />
    </Page>
  )
}

export const getServerSideProps = async ({ locale, draftMode = false }) => {
  if (isDefaultOrEmptyLocale(locale)) {
    return {
      props: await getStaticPageFallbackProps(locale, draftMode),
    }
  }

  let translations
  let appsConfig
  let headerEntries
  let footerEntries

  try {
    ;[translations, appsConfig, { headerEntries, footerEntries }] = await Promise.all([
      serverSideTranslations(locale, ['cms'], nextI18next),
      getAppConfigProps(),
      sharedContentfulProps({
        locale,
        preview: draftMode,
        requireSiteStructure: true,
      }),
    ])
  } catch (error) {
    Logger.error('Error fetching shared contentful props', error)

    return {
      props: await getStaticPageFallbackProps(locale, draftMode),
    }
  }

  return {
    props: {
      ...translations,
      ...appsConfig,
      headerEntries,
      footerEntries,
      locale,
    },
  }
}

export default LanguageSelector
